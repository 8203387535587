import React, { useState, useEffect, useCallback } from 'react';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';

import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import Loja from '~/pages/Loja';
import InputSelect from '../Inputs/InputSelect';
import { Container } from './styles';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface ISelect {
  label: string;
  value: number;
}

interface IEntidade {
  cod_finalizadora: number;
  des_finalizadora: string;
  num_finalizadora: number;
  cod_condicao: number;
}

interface EntidadeProps {
  value: Options | undefined;
  loja: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const ContaBanco: React.FC<EntidadeProps> = (props) => {
  const {
    loja,
    onChange,
    value,
    isRequired,
    isDisabled,
    setInvalid,
    iniInicializado,
    menuPosition,
    menuPlacement,
    minMenuHeight,
  } = props;
  // const user = useAuth();
  // const { options } = props;
  // armazena se o input passou na validação

  // armazena se a validação foi iniciada
  const [optionsEntidade, setOptionsEntidade] = useState<Options[]>([]);
  const [entidades, setEntidades] = useState<ISelect>();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/finalizadora/lojas/${loja}`);

      if (data.success && data.data) {
        const options = data.data.map((entidade: IEntidade) => ({
          label: `${entidade.des_finalizadora}`,
          value: entidade.cod_finalizadora,
        }));
        setOptionsEntidade(options);
      }
    })();
  }, [loja]);

  const handleChangeEntidade = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
      setEntidades(val);
    },
    [onChange],
  );

  return (
    <Container>
      <div className="col-sm-12">
        <InputSelect
          placeholder={
            isDisabled === true ? 'Informe a Loja Primeiro' : 'Selecione'
          }
          options={optionsEntidade}
          label="Finalizadora"
          noOptionsMessage="Nenhum registro encontrado"
          value={value}
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          iniInicializado={iniInicializado}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangeEntidade(newValue, isInvalid);
          }}
        />
      </div>
    </Container>
  );
};

export default ContaBanco;
