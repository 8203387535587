/* eslint-disable no-restricted-globals */
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  FiltroContextDataProps,
  Filter,
  FiltroContextProviderProps,
} from './types';
import { MacroContext } from '~/context/macros';

const JsonCondition = [
  {
    cod_condicao: 0,
    des_condicao: 'Igual à',
  },
  {
    cod_condicao: 1,
    des_condicao: 'Maior ou igual à',
  },
  {
    cod_condicao: 2,
    des_condicao: 'Menor ou igual à',
  },
  {
    cod_condicao: 3,
    des_condicao: 'Diferente de',
  },
  {
    cod_condicao: 4,
    des_condicao: 'Contém',
  },
  {
    cod_condicao: 5,
    des_condicao: 'Não Contém',
  },
  {
    cod_condicao: 6,
    des_condicao: 'Entre',
  },
  {
    cod_condicao: 7,
    des_condicao: 'Preenchido',
  },
  {
    cod_condicao: 8,
    des_condicao: 'Não Preenchido',
  },
  {
    cod_condicao: 9,
    des_condicao: 'Maior que',
  },
  {
    cod_condicao: 10,
    des_condicao: 'Menor que',
  },
];

export const FiltroContext = createContext({} as FiltroContextDataProps);

export function FiltroContextProvider({
  zindex,
  handleSetValue,
  filter,
  removeFilter,
  searchOnClick,
  isPopUp,
  isFilterBlock,
  children,
}: FiltroContextProviderProps) {
  // Condições
  const preenchido = 7;
  const naoPreenchido = 8;

  const [isOpenFiltro, setOpenFiltro] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const { getMacro } = useContext(MacroContext);

  useEffect(() => {
    if (isPopUp) {
      setOpenFiltro(true);
      FocusFilter();
    }
  }, [isPopUp]);

  const handleSetNewValue = (item: Filter) => {
    setOpenFiltro(false);
    handleSetValue(item);
  };
  const handlCondition = (cod_condicao: number) => {
    const itemCondition = JsonCondition.filter((item) => {
      return item.cod_condicao === cod_condicao;
    });
    return ` ${itemCondition[0].des_condicao} `;
  };

  const formattedDate = (dateInput: string) => {
    const date = new Date(dateInput);
    const dateformat = date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    return dateformat;
  };

  useEffect(() => {
    setValue('');
    const itens = filter.des_valor;
    if (filter.condicao === naoPreenchido || filter.condicao === preenchido) {
      return;
    }

    function addMacroOrFormat(item: string) {
      const findMacro =
        filter.cod_tipo_info > 0 ? getMacro(filter.cod_tipo_info) : undefined;
      const isDate = filter.cod_tipo_dados === 1 || filter.cod_tipo_dados === 2;

      if (findMacro) {
        const findValue = findMacro.items.find(
          (m) => m.cod_item === parseInt(item, 10),
        );
        if (findValue) return findValue.des_item;
      }

      const numberItem = Number(item);
      if (item && filter.cod_tipo_dados === 3 && !isNaN(numberItem)) {
        return new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(numberItem);
      }

      if (item && filter.cod_tipo_dados === 4 && !isNaN(numberItem)) {
        let numberFractionDigits = 2;
        if (filter.listagem_info) {
          const listagem_info_parse = JSON.parse(filter.listagem_info);
          const listagem_info_parse_split =
            listagem_info_parse[0].valor.split(',');
          numberFractionDigits =
            listagem_info_parse_split.length > 1
              ? listagem_info_parse_split[1].length
              : 2;
        }

        return new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: numberFractionDigits,
          maximumFractionDigits: 3,
        }).format(numberItem);
      }

      if (filter.cod_tipo_dados === 6) {
        return item.replace('true', 'Verdadeiro').replace('false', 'Falso');
      }
      return isDate ? formattedDate(item) : item;
    }
    if (Array.isArray(itens)) {
      const labelFilter = [];
      const concatenador = filter.condicao === 6 ? ' e ' : ', ';
      // eslint-disable-next-line no-restricted-syntax
      for (const item of itens) {
        labelFilter.push(addMacroOrFormat(item));
      }
      setValue(labelFilter.join(concatenador));
    } else {
      setValue(addMacroOrFormat(filter.des_valor));
    }
  }, [filter, getMacro]);

  useEffect(() => {
    FocusFilter();
  }, [filter]);

  async function FocusFilter() {
    const input = await document.getElementById(filter.index);
    input?.focus();
    const inputTag = await document.getElementById('inputTag');
    inputTag?.focus();
  }

  return (
    <FiltroContext.Provider
      value={{
        zindex,
        searchOnClick,
        isOpenFiltro,
        value,
        handlCondition,
        setOpenFiltro,
        FocusFilter,
        handleSetNewValue,
        filter,
        removeFilter,
        isFilterBlock,
      }}
    >
      {children}
    </FiltroContext.Provider>
  );
}

export const useFiltro = (): FiltroContextDataProps => {
  return useContext(FiltroContext);
};
