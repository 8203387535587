/* eslint-disable no-restricted-globals */
import React from 'react';
import Button from '@atlaskit/button';
import { AiFillCloseCircle, AiFillLock } from 'react-icons/ai';

import { ContainerValues } from '../../styles';
import { useFiltro } from '../../FiltroContext';

const HeaderFiltro: React.FC = () => {
  const {
    isOpenFiltro,
    handlCondition,
    value,
    filter,
    removeFilter,
    isFilterBlock,
    setOpenFiltro,
    FocusFilter,
  } = useFiltro();

  return (
    <ContainerValues key={filter.index}>
      <div style={{ display: 'flex' }}>
        <Button
          type="button"
          className="filtroText"
          shouldFitContainer
          spacing="compact"
          style={{
            maxWidth: filter.des_nome === 'Loja' ? '800px' : '350px',
          }}
          // eslint-disable-next-line no-nested-ternary
          appearance={
            isFilterBlock ? 'warning' : isOpenFiltro ? 'primary' : 'default'
          }
          onClick={() => {
            setOpenFiltro(!isOpenFiltro);
            FocusFilter();
          }}
        >
          {` ${filter.des_nome} `}
          <span>
            {handlCondition(filter.condicao)}
            {value}
          </span>
        </Button>
        {isFilterBlock ? (
          <Button
            type="button"
            spacing="compact"
            className="closed"
            appearance="warning"
          >
            <AiFillLock />
          </Button>
        ) : (
          <Button
            type="button"
            spacing="compact"
            className="closed"
            style={{ backgroundColor: isOpenFiltro ? '#004ab7' : '#dcdfe0' }}
            appearance={isOpenFiltro ? 'primary' : 'default'}
            onClick={() => removeFilter(filter)}
          >
            <AiFillCloseCircle />
          </Button>
        )}
      </div>
    </ContainerValues>
  );
};

export default HeaderFiltro;
